import { useEffect } from "react";
import { db } from './../../firebase-config';
import { collection, doc, getDoc, setDoc, onSnapshot, addDoc, query, orderBy, serverTimestamp } from "firebase/firestore";

const ChatRoom = ({ discordChannelId, setMessages, setMessage, userId, firstName, school, profileEmoji }) => {
  const isValidGifUrl = (url) => {
    return url.match(/\.(gif)$/i);
  };

  useEffect(() => {
    if (!discordChannelId) return;

    const checkOrCreateChatRoom = async () => {
      const chatRoomRef = doc(db, "tournamentChat", discordChannelId);
      const chatRoomSnap = await getDoc(chatRoomRef);

      if (!chatRoomSnap.exists()) {
        await setDoc(chatRoomRef, { createdAt: new Date() });
      }
    };

    checkOrCreateChatRoom();
  }, [discordChannelId]);

  useEffect(() => {
    if (!discordChannelId) return;

    const messagesRef = collection(db, "tournamentChat", discordChannelId, "messages");
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [discordChannelId, setMessages]);

  const sendMessage = async (message) => {
    if (!discordChannelId || message.trim() === "") return;

    try {
      const messageToSend = isValidGifUrl(message)
        ? `<img style="width:200px" src="${message}" alt="GIF" />`
        : message;

      const messagesRef = collection(db, "tournamentChat", discordChannelId, "messages");

      await addDoc(messagesRef, {
        text: messageToSend,
        timestamp: serverTimestamp(),
        userId,
        firstName,
        school,
        profileEmoji,  // ✅ Storing the profile emoji
      });

      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return {
    sendMessage,
  };
};

export default ChatRoom;
