import React, { useState } from 'react';
import axios from 'axios';

const GIFBrowser = ({ onSelectGif, closeGifBrowser }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [gifs, setGifs] = useState([]);
  const [loading, setLoading] = useState(false);

  const GIPHY_API_KEY = '8pvenepwayXq5FkxbrGit9q8O8juOLxn'; // Replace with your actual Giphy API key

  const fetchGifs = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.giphy.com/v1/gifs/search`, {
        params: {
          api_key: GIPHY_API_KEY,
          q: query,
          limit: 10,
        },
      });
      setGifs(response.data.data);
    } catch (error) {
      console.error("Error fetching GIFs", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value) {
      fetchGifs(event.target.value);
    } else {
      setGifs([]);
    }
  };

  const handleGifClick = (gifUrl) => {
    onSelectGif(gifUrl); // Send GIF URL to input field
    closeGifBrowser(); // Close GIF browser after selection
  };

  return (
    <div
      style={{
        padding: '10px',
        background: '#191919', 
        borderRadius: '8px',
        border: '2px solid #CCF900',
        maxWidth: '380px',
        margin: '10px auto',
      }}
    >
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search for GIFs..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{
          padding: '8px',
          fontSize: '14px',
          width: '100%',
          borderRadius: '25px',
          border: 'none',
          outline: 'none',
          backgroundColor: '#111',
          color: '#fff',
          fontStyle: 'italic',
          height: '30px',
        }}
      />
      
      {loading && <p style={{ color: '#CCF900', fontSize: '12px', textAlign: 'center' }}>Loading...</p>}

      {/* GIF Display Grid */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'center' }}>
        {gifs.map((gif) => (
          <div key={gif.id} style={{ cursor: 'pointer' }} onClick={() => handleGifClick(gif.images.fixed_height.url)}>
            <img src={gif.images.fixed_height.url} alt={gif.title} style={{ width: '80px', height: '80px', borderRadius: '8px' }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GIFBrowser;
